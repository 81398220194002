<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <QuickTranslation translation-key="records.label" tag="h1" />
      </v-list-item-content>
    </v-list-item>
    <v-tabs center-active :slider-size="3" color="primary" item-key="name" v-model="selectedTab"
      show-arrows>
      <v-tab v-for="(storageTypeTab, index) in storageDeviceTypesActiveByCompany" :key="index"
        @click="getDataByFilters($event, storageTypeTab)" :disabled="isDisable">
        <v-img :src="storageTypeTab.iconBase64" class="mb-1 mr-2" max-height="30" min-height="30" max-width="30"
          min-width="30" eager />
        {{ storageTypeTab.storage_type_name }}
      </v-tab>
    </v-tabs>
    <record-table :stage="stage"
      v-if="storageDeviceTypesActiveByCompany && storageDeviceTypesActiveByCompany.length > 0"
      @isDisable="isDisableTabs($event)" />
  </v-container>
</template>

<script>
import RecordTable from "./RecordsTableComponent";
import _ from "lodash";
import { mapGetters } from 'vuex'
import LanguajeService from '@/services/LanguajeService'

export default {
  name: 'records-tab',
  props: ['stage'],
  components: {
    RecordTable
  },
  data: () => ({
    // User Profile
    profile: null,
    isDisable: true,
    selectedTab: 0,
    tabStateMap: {}, // Maps stageId to tab index
    initialLoad: true
  }),

  computed: {
    storageDeviceTypesActiveByCompany() {
      return _.sortBy(this.$store.getters['storage_type/storageDeviceTypesActiveByCompany'], 'disposition')
    },

    filterRecord: {
      get() {
        return this.$store.getters['ui/GET_STAGE_ID'] || {
          stageId: this.storageDeviceTypesActiveByCompany[0]?.storage_device_type_id
        }
      },
      set(val) {
        this.$store.dispatch('ui/SET_STAGE_ID', { val })
      },
    },
  },

  watch: {
    selectedTab: {
      handler(newTabIndex) {
        if (this.storageDeviceTypesActiveByCompany.length > 0) {
          const newStage = this.storageDeviceTypesActiveByCompany[newTabIndex]
          if (newStage) {
            // Update the stageId in the store
            this.filterRecord.stageId = newStage.storage_device_type_id

            // Save the tab state to localStorage
            this.saveTabState(newTabIndex, newStage.storage_device_type_id)

            // Trigger event to refresh records with the new stageId
            this.$root.$emit('getResultsPaginatedByCompany')
          }
        }
      }
    },

    storageDeviceTypesActiveByCompany: {
      handler() {
        this.buildTabStateMap()

        // Only try to restore tab on initial load
        if (this.initialLoad) {
          this.restoreTabState()
          this.initialLoad = false
        }
      },
      immediate: true
    },

    '$route': {
      handler(newRoute) {
        // Check if the route has a stage parameter when route changes
        if (newRoute.params && newRoute.params.stage) {
          this.handleRouteStageParam(newRoute.params.stage);
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.buildTabStateMap();

    // First check if there's a stage param in the route - this takes priority
    if (this.$route.params && this.$route.params.stage) {
      this.handleRouteStageParam(this.$route.params.stage);
    } else {
      // Otherwise restore from localStorage
      this.restoreTabState();
    }
  },

  methods: {
    translate(stage) {
      return LanguajeService.getKey3(stage.language_key)
    },

    buildTabStateMap() {
      // Create a mapping between stageId and tab index
      this.tabStateMap = {}
      this.storageDeviceTypesActiveByCompany.forEach((stage, index) => {
        this.tabStateMap[stage.storage_device_type_id] = index
      })
    },

    getTabIndex(stageId) {
      return this.tabStateMap[stageId] ?? 0
    },

    saveTabState(tabIndex, stageId) {
      // Save the tab state as a tuple of {tabIndex, stageId}
      const tabState = { tabIndex, stageId }
      localStorage.setItem('recordsTabState', JSON.stringify(tabState))
    },

    restoreTabState() {
      // Try to restore tab state from localStorage
      const savedState = localStorage.getItem('recordsTabState')

      if (savedState) {
        try {
          const { tabIndex, stageId } = JSON.parse(savedState)

          // Verify the saved stageId is still valid in our current list
          if (this.storageDeviceTypesActiveByCompany.some(stage => stage.storage_device_type_id === stageId)) {
            // Set the tab index
            this.selectedTab = this.getTabIndex(stageId)

            // Set the stageId in the store
            this.filterRecord.stageId = stageId
          } else {
            // If the saved stageId is no longer valid, reset to the first tab
            this.resetToDefaultTab()
          }
        } catch (e) {
          console.error('Error restoring tab state:', e)
          this.resetToDefaultTab()
        }
      } else {
        this.resetToDefaultTab()
      }
    },

    resetToDefaultTab() {
      if (this.storageDeviceTypesActiveByCompany.length > 0) {
        const defaultStage = this.storageDeviceTypesActiveByCompany[0]
        this.selectedTab = 0
        this.filterRecord.stageId = defaultStage.storage_device_type_id
        this.saveTabState(0, defaultStage.storage_device_type_id)
      }
    },
    async getDataByFilters(e, storageTypeTab) {
      if (e) {
        this.filterRecord.stageId = storageTypeTab.storage_device_type_id;
      } else {
        this.filterRecord.stageId = this.stage;
      }

      // Set loading state immediately when tab changes
      this.isDisable = true;

      // Explicitly set loading indicators in RecordsTableComponent
      this.$root.$emit('set:loading:state', true);

      // Save the tab state
      const tabIndex = this.getTabIndex(this.filterRecord.stageId);
      this.saveTabState(tabIndex, this.filterRecord.stageId);

      // Trigger data loading
      this.$root.$emit('getResultsPaginatedByCompany');
    },
    isDisableTabs(event) {
      this.isDisable = event;
    },

    handleRouteStageParam(stageParam) {
      // Convert the param to number if it's a string
      const stageId = typeof stageParam === 'string' ? parseInt(stageParam, 10) : stageParam;

      // Check if the stageId is valid in our current list
      const stageExists = this.storageDeviceTypesActiveByCompany.some(
        stage => stage.storage_device_type_id === stageId
      );

      if (stageExists) {
        console.log('Setting tab by route param:', stageId);
        // Set the tab index based on stageId
        this.selectedTab = this.getTabIndex(stageId);

        // Set the stageId in the store
        this.filterRecord.stageId = stageId;

        // Update localStorage with the new state
        this.saveTabState(this.selectedTab, stageId);

        // Trigger data loading with the new stageId
        this.$nextTick(() => {
          this.$root.$emit('getResultsPaginatedByCompany');
        });
      } else {
        console.warn('Route contains invalid stage ID:', stageId);
        this.resetToDefaultTab();
      }
    }
  },

  destroyed() {
    this.filterRecord.stageId = null;
    // localStorage.removeItem('recordsTabIndex');
  }
}
</script>

<style scoped>
</style>
