<template>
  <v-dialog v-model="dialog" persistent width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text @click="openDialog()" :disabled="disable">
        <v-icon left small color="neuter">mdi-download-multiple</v-icon>
        PDF
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline secondary t-bw-secondary--text">{{
          $t('reports.titles.exportConfigPDF')
        }}</v-card-title>
      <v-card-text class="pa-6">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :no-data-text="$t('common.fields.noDataAvailable')"
              color="primary"
              outlined
              dense
              :label="$t('reports.titles.orientation')"
              item-color="primary"
              :items="pageEnum"
              item-value="type"
              item-text="text"
              v-model="orientation"
              small-chips
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="d-flex justify-center">
            <v-switch inset v-model="evidencePresent">
              <template v-slot:prepend>
                <v-label ref="label"
                >{{ $t('reports.titles.evidences') }}
                </v-label>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="6">
            <v-switch inset v-model="documentPresent">
              <template v-slot:prepend>
                <v-label ref="label"
                >{{ $t('reports.titles.documents') }}
                </v-label>
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn
          small
          text
          color="neuter"
          class="mr-1"
          @click="dialogExportPDFClose"
          :disabled="loading"
        >
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          small
          elevation="0"
          class="t-bw-primary--text"
          color="primary"
          :disabled="!orientation"
          :loading="loading"
          @click="exportPDFRecords"
        >
          {{ $t('common.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import i18n from '@/plugins/i18n'
import { mapActions, mapGetters } from 'vuex'
import LanguageService from '@/services/LanguajeService'
import { checkSameType } from '@/utils'

  export default {
    name: 'ExportPDFComponent',

    props: {
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      search: {
        type: String,
        required: false,
        default: ''
      },
      selectedItems: {
        type: Array,
        required: false,
        default: () => {
          return []
        }
      },
      totalFromServer: {
        type: Number,
        required: true
      },
      pageable: {
        type: Object,
        required: true,
        default: () => {
          return {
            page: 0,
            size: 10,
            sortBy: 'id',
            direction: 'desc',
          }
        }
      }
    },

    data:() => ({
      profile: JSON.parse(localStorage.getItem('profile')),
      dialog: false,
      loading: false,
      orientation: null,
      documentPresent: true,
      evidencePresent: true
    }),

    computed: {
      ...mapGetters({
        pageEnum: 'reports/pageEnum',
        storageDeviceTypesActiveByCompany:'storage_type/storageDeviceTypesActiveByCompany',
        process: 'proccess/proccess',
        secondaries: 'storage/GET_STORAGES_FOR_FILTERS',
        filters: 'ui/GET_FILTERS',
        filtersObjects: 'ui/GET_OBJECTS_TO_FILTERS',
        filterRecord: 'ui/GET_STAGE_ID',
        date: 'ui/GET_DATE_FILTERS',
        config: 'general/config'
      })
    },

    methods: {
      ...mapActions({
        exportRecordsAction: 'records/exportRecords',
      }),
      openDialog() {
        this.$emit('closeMenu', false)
        this.dialog = true
      },
      async exportPDFRecords() {
        this.loading = true

        const keys = [
          'quebra_range',
          'sellers_names',
          'contracts_names',
          'contracts_dates',
          'volumeB_range',
          'volumeN_range',
          'surveyStateType',
          'template_names',
          'min_punctuation',
          'max_punctuation',
          'storage_devices_ids',
          'document_authority_ids',
          'document_type_ids',
          'document_description_ids',
          'document_date',
          'expiration_date',
        ]

        if (!this.filters.pageableDTO)
          this.filters.pageableDTO = _.cloneDeep(this.pageable)

        let filters = _.omit(this.filters, keys)

        filters.record_ids = []

        if (this.selectedItems && this.selectedItems.length > 0) {
          filters.record_ids =
            this.selectedItems && this.selectedItems.length > 0
              ? this.getByProperty(this.selectedItems, 'id')
              : []
        }

        let fields = []
        _.forEach(filters, (filter, key) => {
          if (
            (filters[key] && !(filters[key] instanceof Array) )||
            (filters[key] &&
              filters[key] instanceof Array &&
              filters[key].length > 0) ||
            (filters[key] instanceof Date && filters[key].length > 0)
          ) {
            switch (key) {
              case 'company_id':
                fields.push({
                  key: i18n.t('company.label'),
                  value: this.profile.company_name_key,
                })
                return
              case 'lots':
                if (this.filters.lots && this.filters.lots.length > 0) {
                  fields.push({
                    key: i18n.t('records.filters.lot'),
                    value: this.filters.lots.join(', '),
                  })
                }
                return
              case 'person_in_charge_ids':
                fields.push({
                  key: i18n.t('records.fields.person'),
                  value: this.getByProperty(
                    this.filtersObjects.person_in_charge_ids,
                    'name'
                  ).join(', '),
                })
                return
              case 'process_ids':
                let processIds = this.filtersObjects.process_ids;
                if (checkSameType(this.filtersObjects.process_ids, 'number')) {
                  processIds = this.process.filter((p) => { return this.filtersObjects.process_ids.includes(p.id) });
                }

                fields.push({
                  key: i18n.t('records.fields.process'),
                  value: this.getByProperty(processIds,
                    'proccess_name'
                  ).join(', '),
                })
                return
              case 'products_ids':
                fields.push({
                  key: i18n.t('records.titles.labels.product'),
                  value: this.getByProperty(
                    this.filtersObjects.products_ids,
                    'product_name'
                  ).join(', '),
                })
                return
              case 'sdi_origin_ids':
                let originIds = this.filtersObjects.sdi_origin_ids;
                if (checkSameType(this.filtersObjects.sdi_origin_ids, 'number')) {
                  originIds = this.secondaries.filter((l) => { return this.filtersObjects.sdi_origin_ids.includes(l.storageDeviceId) });
                }
                fields.push({
                  key: i18n.t('records.fields.origin'),
                  value: this.getByProperty(originIds,
                    'control_label'
                  ).join(', '),
                })
                return
              case 'sdi_destination_ids':
                let destinationIds = this.filtersObjects.sdi_destination_ids;
                if (checkSameType(this.filtersObjects.sdi_destination_ids, 'number')) {
                  destinationIds = this.secondaries.filter((l) => { return this.filtersObjects.sdi_destination_ids.includes(l.storageDeviceId) });
                }
                fields.push({
                  key: i18n.t('records.fields.destination'),
                  value: this.getByProperty(destinationIds,
                    'control_label'
                  ).join(', '),
                })
                return
              case 'seasons_ids':
                fields.push({
                  key: i18n.t('records.fields.season'),
                  value: this.getByProperty(
                    this.filtersObjects.seasons_ids,
                    'season_name'
                  ).join(', '),
                })
                return
              case 'end_record_date_time':
                fields.push({
                  key: i18n.t('records.fields.date'),
                  value: this.date.filtersDate,
                })
                return
              case 'storage_device_types_ids':
                const storageType = _.find(
                  this.storageDeviceTypesActiveByCompany,
                  { storage_device_type_id: this.filterRecord.stageId }
                )
                fields.push({
                  key: i18n.t('pre-records.fields.stage'),
                  value: storageType.storage_type_name,
                })
                return
            }
          }
        })

        filters.filterValues = fields
        filters.language = LanguageService.getLenguajeName()
        filters.withEvidences = this.evidencePresent
        filters.withDocuments = this.documentPresent
        filters.orientation = this.orientation
        filters.currentDate = moment(new Date()).format().slice(0, 19)

        if ((this.search && this.search !== '') && !(this.selectedItems && this.selectedItems.length > 0)) {
          filters.export_by_string = true
          filters.string_filter = {
            companyId: this.profile.company_id,
            stringFilter: this.search,
            storageDeviceTypeDTO: {
              id: this.filterRecord.stageId,
            },
            pageableDTO: this.filters.pageableDTO
          }

          filters = _.pick(filters, ['export_by_string', 'string_filter', 'language', 'withEvidences', 'withDocuments', 'orientation', 'currentDate', 'pageableDTO'])
        } else {
          filters.export_by_string = false
          filters.string_filter = null
        }

        let isAsync = false;
        if (this.selectedItems && this.selectedItems.length > 0) {
          isAsync = this.selectedItems.length > this.config.company.export.recordExportLimit
        } else {
          isAsync = this.totalFromServer > this.config.company.export.recordExportLimit
        }

        await this.exportRecordsAction([
          filters,
          null,
          'pdf',
          this.$toast,
          isAsync
        ]).finally(() => {
          this.loading = false
          this.dialogExportPDFClose()
        })
      },
      dialogExportPDFClose() {
        this.orientation = null
        this.documentPresent = true
        this.evidencePresent = true
        this.dialog = false
        this.loading = false
      },
      getByProperty(array, property) {
        return _.map(array, property)
      }
    }
  }
</script>

<style scoped>

</style>