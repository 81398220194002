<template>
  <v-dialog v-model='dialog' persistent width='600px'>
    <template v-slot:activator='{ on, attrs }'>
      <v-btn v-bind="attrs" small text @click.stop='fetchEvidences()'>
        <v-icon
          v-if="
            checkAdmin() ||
            (check([{ domain: 'RecordEvidence', permisions: ['Update'] }]))
          "
          color='black'
          left
          small
        >
          mdi-file-document-multiple-outline
        </v-icon>
        {{ $t('records.titles.evidences') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class='headline secondary t-bw-secondary--text'>
        {{ $t('reports.titles.evidences') }}
      </v-card-title>
      <v-card-text class="pa-6">
        <v-overlay :value='loading' absolute color='white'>
          <v-progress-circular color='secondary' indeterminate />
        </v-overlay>

        <v-row>
          <v-col cols='12'>
            <v-form ref='formEvidences' v-model='valid'>
              <v-file-input
                v-model='filesSelected'
                :label="$t('common.titles.evidence')"
                :rules='fileTypeRulesAndSize(filesSelected)'
                append-icon='mdi-file-document-multiple'
                color='secondary'
                multiple
                prepend-icon=''
                small-chips
                outlined
                dense
                :clearable='!valid'
                @change='addDocument()'
              >
              </v-file-input>
            </v-form>
          </v-col>
          <v-col cols='12' style='overflow-y: auto; height: 20vh; max-height: 100%;'>
            <v-card v-if='documents.length > 0' class='mb-5' elevation='0'>
              <v-list>
                <v-list-item v-for='(doc, index) in documents' :key='index'>
                  <v-list-item-icon>
                    <v-icon color="neuter">
                      {{ selectIconFile(doc.type) }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ doc.name }}</v-list-item-title>
                    <v-list-item-subtitle :class='{ "red--text" : (doc.size > 5000000) }'><strong>{{ size(doc.size) }}</strong></v-list-item-subtitle>

                    <v-divider class="mt-4" />
                  </v-list-item-content>

                  <v-list-item-action style='display: contents'>
                    <v-btn color='danger' icon @click="deleteFile(index, 'document')">
                      <v-icon color='secondary'>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card v-if='evidences.length > 0' elevation='0'>
              <v-list>
                <v-list-item v-for='(doc, index) in evidences' :key='index'>
                  <v-list-item-icon>
                    <v-icon color="neuter">
                      {{ selectIconFile(doc.file_extension) }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ doc.id }}</v-list-item-title>
                    <v-list-item-subtitle>{{ size(doc.size) }}</v-list-item-subtitle>

                    <v-divider class="mt-4" />
                  </v-list-item-content>

                  <v-list-item-action style='display: contents'>
                    <v-btn color='danger' icon @click="deleteFile(index, 'evidence')">
                      <v-icon color='secondary'>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions class="pa-6">
        <v-btn
          v-if='
                evidences && evidences.length > 0
              '
          :disabled='loadingDownload'
          :loading='loadingDownload'
          class='ma-2 white--text'
          color='blue-grey'
          small
          text
          @click='getEvidences'
        >
          <v-icon color='secondary'> mdi-download-multiple</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled='loading'
          color='neuter'
          small
          text
          @click='closeEvidences()'
        >
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          :disabled='loading || !valid || validateDocuments'
          elevation="0"
          class="t-bw-primary--text"
          color='primary'
          small
          @click='updateEvidences()'
        >
          {{ $t('common.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import _ from 'lodash'
  import i18n from '@/plugins/i18n'
  import { mapActions, mapGetters } from 'vuex'
  import CompactImages from '@/services/CompactImages'
  import DocumentsService from '@/services/DocumentsService'
  import PermissionService from '@/services/PermisionService'

  export default {
    name: 'EvidenceDialogComponent',

    props: {
      record: {
        type: Object,
        require: true,
      },
    },

    data() {
      return {
        profile: JSON.parse(localStorage.getItem('profile')),
        dialog: false,
        valid: false,
        loading: false,
        loadingDownload: false,
        filesSelected: [],
        documents: []
      }
    },

    computed: {
      ...mapGetters({
        fileTypeRulesAndSize: 'general/fileTypeRulesAndSize',
      }),
      evidences: {
        get() {
          return this.$store.getters['records/evidences']
        },
        set(val) {
          this.$store.commit('records/SET_EVIDENCES', val)
        },
      },
      validateDocuments() {
        let count = 0
        _.find(this.documents, (doc) => {
          if (doc.size > 5000000) count++
        })

        return count > 0
      }
    },

    methods: {
      ...mapActions({
        fetchEvidencesByRecord: 'records/fetchEvidencesByRecord',
        updateEvidencesByRecord: 'records/updateEvidencesByRecord',
      }),
      async fetchEvidences() {
        this.$emit('close:options', false)
        this.loading = true
        this.dialog = true

        await this.fetchEvidencesByRecord([this.record.id, this.$toast]).finally(() => {
          this.loading = false
        })
      },
      async getEvidences() {
        try {
          this.loadingDownload = true

          await DocumentsService.getEvidencas(this.record.id).then(
            response => {
              const FileSaver = require('file-saver')
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              FileSaver.saveAs(blob, 'evidences.zip')
            },
          )
        } catch (error) {
          this.$toast.info(i18n.t('records.notification.evidencesCero'), {
            icon: 'mdi-information-slab-circle',
            queueable: true
          })
        } finally {
          this.loadingDownload = false
        }
      },
      async addDocument() {
        const mimeType = [
          'image/jpeg',
          'image/jpg',
          'image/bmp',
          'image/png',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'text/csv',
          'text/xml',
          'application/pdf',
          'application/zip',
          'application/x-zip-compressed',
          'application/vnd.rar',
        ]
        const scope = this

        _.forEach(this.filesSelected, function(doc) {
          const split = doc.name.split('.')
          const extension = doc ? split[split.length - 1] : null

          if (['jpg', 'jpeg', 'png', 'bmp', 'pdf', 'rar', 'zip', 'xml', 'xlsx', 'csv'].includes(extension)) {
            if (['jpg', 'jpeg', 'png', 'bmp'].includes(extension)) {
              CompactImages.downscaleImage(0.7, doc, function(data) {
                if (data.size <= 5000000 && mimeType.includes(data.type))
                  scope.documents.push(data)
              })
            } else {
              scope.documents.push(doc)
            }
          }
        })

        this.filesSelected = []
      },
      deleteFile(index, storage) {
        switch (storage) {
          case 'evidence':
            this.evidences.splice(index, 1)
            break
          case 'document':
            _.forEach(this.filesSelected, (file, i) => {
              if((this.documents[index] && this.documents[index].name) && (file && file.name)) {
                if(file.name === this.documents[index].name) {
                  this.filesSelected.splice(i, 1)
                }
              }
            })
            this.documents.splice(index, 1)
            break
        }
      },
      async updateEvidences() {
        this.loading = true

        const document = {
          documentAuthority: { id: 1 },
          documentType: { id: 1 },
          documentDescription: { id: 1 },
          company: { id: this.profile.company_id },
        }

        const currents = this.evidences.map(e => e.id)

        await this.updateEvidencesByRecord([this.record.id, document, currents, this.documents, this.$toast]).finally(() => {
          this.closeEvidences()
        })
      },
      closeEvidences() {
        this.loading = false
        this.dialog = false
        this.filesSelected = []
        this.documents = []
        this.$store.commit('records/RESTORE_EVIDENCES')
        this.$refs.formEvidences.reset()
      },
      // Check permission
      check(permissions) {
        return PermissionService.check(permissions)
      },
      // Check permission Admin
      checkAdmin() {
        return PermissionService.checkAdmin()
      },
      size(size) {
        return DocumentsService.formatterSizeUnit(size)
      },
      selectIconFile(extension) {
        return DocumentsService.selectIcon(extension)
      },
    },
  }
</script>

<style scoped>

</style>
