<template>
  <v-dialog v-if="stageProcesses.length !== 1" v-model="dialog" max-width="900px" persistent>
    <v-card class="process-dialog">
      <v-card-title class="dialog__header px-6 py-4">
        <h2 class="text-h5 font-weight-medium">{{ $t("process.selector.title") }}</h2>
      </v-card-title>

      <v-card-text class="pa-4">
        <v-row dense>
          <v-col 
            v-for="process in stageProcesses" 
            :key="process.id"
            cols="12"
            sm="6"
            md="4"
            class="mb-2 px-2"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                flat
                class="process-card d-flex align-center"
                :class="{ 'on-hover': hover }"
                @click="selectProcess(process)"
              >
                <div 
                  class="process-icon-wrapper"
                  :style="{ backgroundColor: `${process.appearance?.color || '#424242'}` }"
                >
                  <v-icon 
                    size="24"
                    class="process-icon"
                    :color="getContrastYIQ(process.appearance?.color)"
                  >
                    {{ process.appearance?.icon || 'mdi-sitemap' }}
                  </v-icon>
                </div>
                
                <div class="process-info ml-4">
                  <h3 class="process-name mb-0">
                    {{ process.proccess_name }}
                  </h3>
                </div>

                <v-icon 
                  class="ml-auto mr-2 transition-transform"
                  :class="{ 'arrow-hover': hover }"
                >
                  mdi-chevron-right
                </v-icon>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-6 dialog-actions">
        <v-spacer />
        <v-btn 
          color="primary" 
          elevation="0"
          small
          @click="dialog = false"
          class="cancel-btn t-bw-primary--text"
        >
          {{ $t("common.buttons.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProcessSelectorDialog',
  props: {
    value: { type: Boolean, required: true },
    stageId: { type: [String, Number], required: true }
  },
  data() {
    return {
      dialog: this.value,
    }
  },
  computed: {
    ...mapGetters({
      processes: 'authority/authoritiesProcess',
    }),
    stageProcesses() {
      return this.processes.filter(process => 
        process.sdt_to_show.id === parseInt(this.stageId)
      );
    },
  },
  methods: {
    selectProcess(process) {
      // Ensure we're using the correct stageId from current tab state
      const tabState = localStorage.getItem('recordsTabState');
      let stageId = this.stageId;
      
      if (tabState) {
        try {
          const { stageId: savedStageId } = JSON.parse(tabState);
          stageId = savedStageId;
        } catch (e) {
          console.error('Error parsing tab state:', e);
        }
      }
      
      // localStorage.setItem('records_editOrSave', -1);
      this.$store.commit('records/SET_ORIGINAL_STATE_RECORD');
      this.$router.push({
        path: '/recordsAdd',
        query: {
          stageId: stageId,
          processId: process.id
        }
      });
      this.dialog = false;
      this.$emit('input', false);
    },
    getContrastYIQ(hexcolor) {
      if (!hexcolor?.startsWith('#')) return 'white';
      const r = parseInt(hexcolor.substr(1, 2), 16);
      const g = parseInt(hexcolor.substr(3, 2), 16);
      const b = parseInt(hexcolor.substr(5, 2), 16);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return (yiq >= 128) ? 'black' : 'white';
    }
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit('input', val);
    },
  }
}
</script>

<style scoped>
.process-card {
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  padding: 8px;
  background-color: white;
  min-height: 56px;
}

.process-card:hover {
  border-color: #cbd5e1;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.process-icon-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.process-icon {
  transition: transform 0.2s ease;
  font-size: 18px !important;
}

.on-hover .process-icon {
  transform: scale(1.1);
}

.process-info {
  flex: 1;
  min-width: 0;
}

.process-name {
  font-size: 0.813rem;
  font-weight: 500;
  color: #1a202c;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow-hover {
  transform: translateX(4px);
}

.transition-transform {
  transition: transform 0.2s ease;
}

.dialog-actions {
  border-top: 1px solid #e2e8f0;
}

.cancel-btn {
  text-transform: none;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.process-dialog {
  border-radius: 12px;
}
</style>
