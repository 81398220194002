<template>
  <v-dialog v-model="dialog" persistent width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text @click="exportDialogCSV" :disabled="disable">
        <v-icon left small color="neuter">mdi-download-multiple</v-icon>
        CSV
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline secondary t-bw-secondary--text">
        {{ $t('reports.titles.exportConfigCSV') }}
        <v-spacer />
        <span style="font-size: 13px" v-if="number">
            {{ number }} {{ $t('records.titles.labels.recordsFound') }}
          </span>
        <span style="font-size: 13px" v-else-if="number === 0">
            {{ $t('records.titles.labels.notRecordsFound') }}
          </span>
      </v-card-title>
      <v-card-text class="pa-6">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :no-data-text="$t('common.fields.noDataAvailable')"
              color="primary"
              :label="$t('records.fields.process')"
              clearable
              item-color="primary"
              :items="allProcess"
              item-text="proccess_name"
              item-value="proccess_name"
              v-model="processCSVExport"
              return-object
              small-chips
              @change="filterByProcessCSV($event)"
              :loading="loadings.process"
              class="d-flex align-baseline"
              outlined
              dense
              :disabled="loadings.process || loadings.export"
            >
              <template v-slot:item="{ item }">
                <template>
                  <v-list-item-content
                    v-text="item.proccess_name"
                  />
                </template>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip small>
                  <span>{{ item.proccess_name }}</span>
                </v-chip>
              </template>
              <template v-slot:prepend-item>
                <v-list-item
                  v-if="
                        filtersObjects &&
                        filtersObjects.process_ids &&
                        filtersObjects.process_ids.length > 1
                      "
                >
                  <v-list-item-content class="py-0">
                    <div class="row">
                      <div class="col-6 py-0 text-right">
                        <v-btn
                          large
                          text
                          color="primary"
                          :disabled="loadings.process || loadings.export"
                          @click="seeProcess('all')"
                        >
                          {{ $t('records.buttons.show_all') }}
                        </v-btn>
                      </div>
                      <v-divider vertical></v-divider>
                      <div class="col-5 py-0 text-left">
                        <v-btn
                          large
                          text
                          color="primary"
                          :disabled="loadings.process || loadings.export"
                          @click="seeProcess('selected')"
                        >
                          {{ $t('records.buttons.by_filtered') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mb-2"></v-divider>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn small
               text
               color="neuter"
               class="mr-1"
               @click="dialogExportCSVClose">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          small
          elevation="0"
          color="primary"
          class="t-bw-primary--text"
          :disabled="loadings.export || !processCSVExport"
          :loading="loadings.export"
          @click="exportCSV(processCSVExport)"
        >
          {{ $t('common.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import _ from 'lodash'
  import { mapActions, mapGetters } from 'vuex'
  import LanguageService from '@/services/LanguajeService'

  export default {
    name: 'ExportCSVComponent',

    props: {
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      search: {
        type: String,
        required: false,
        default: ''
      },
      selectedItems: {
        type: Array,
        required: false,
        default: () => {
          return []
        }
      },
      totalFromServer: {
        type: Number,
        required: true
      },
      pageable: {
        type: Object,
        required: true,
        default: () => {
          return {
            page: 0,
            size: 10,
            sortBy: 'id',
            direction: 'desc',
          }
        }
      }
    },

    data:() => ({
      profile: JSON.parse(localStorage.getItem('profile')),
      dialog: false,
      orientation: null,
      processCSVExport: null,
      number: null,
      loadings: {
        process: false,
        export: false
      }
    }),

    computed: {
      ...mapGetters({
        filters: 'ui/GET_FILTERS',
        filtersObjects: 'ui/GET_OBJECTS_TO_FILTERS',
        filterRecord: 'ui/GET_STAGE_ID',
        date: 'ui/GET_DATE_FILTERS',
        cantToExport: 'records/cantToExport',
        config: 'general/config'
      }),
      allProcess: {
        get() {
          return this.$store.getters['proccess/proccess']
        },
        set(val) {
          this.$store.commit('proccess/SET_PROCCESS_LIST', val)
        },
      },
    },

    methods: {
      ...mapActions({
        fetchCantToExport: 'records/fetchCantToExport',
        fetchAllProcess: 'proccess/fetchListProccess',
        exportRecordsAction: 'records/exportRecords'
      }),
      filterByProcessCSV(event) {
        if ((event === undefined || event === null) && !this.processCSVExport) {
          this.number = null
          return
        }

        if (event === undefined && this.processCSVExport) {
          event = _.cloneDeep(this.processCSVExport)
        }

        this.loadings.process = true
        const keys = [
          'storage_device_types_ids',
          'quebra_range',
          'sellers_names',
          'contracts_names',
          'contracts_dates',
          'volumeB_range',
          'volumeN_range',
          'surveyStateType',
          'template_names',
          'min_punctuation',
          'max_punctuation',
          'storage_devices_ids',
          'document_authority_ids',
          'document_type_ids',
          'document_description_ids',
          'document_date',
          'expiration_date',
        ]

        if (!this.filters.pageableDTO)
          this.filters.pageableDTO = this.defaultPageableDTO

        let filtered = _.cloneDeep(_.omit(this.filters, keys))

        if (typeof event === 'number') {
          event = this.allProcess.find(process => process.id === event)
        }

        if (!filtered.process_ids) {
          filtered.process_ids = [event.id]
        }

        if (
          this.filtersObjects.process_ids &&
          this.filtersObjects.process_ids.length > 0
        ) {
          if (filtered.process_ids.length >= 1) {
            filtered.process_ids = [event.id]
          }
        }

        filtered.storage_device_types_ids = [event.sdt_to_show.id]

        let record_ids = null
        if (this.selectedItems && this.selectedItems.length > 0) {
          record_ids = this.getByProperty(this.selectedItems, 'id')
          filtered.record_ids = record_ids
        }

        if ((this.search && this.search !== '') && !(this.selectedItems && this.selectedItems.length > 0)) {
          filtered.export_by_string = true
          filtered.string_filter = {
            companyId: this.profile.company_id,
            stringFilter: this.search,
            storageDeviceTypeDTO: {
              id: this.filterRecord.stageId,
            },
            pageableDTO: this.filters.pageableDTO,
            process_id: filtered.process_ids[0]
          }

          filtered = _.pick(filtered, ['language', 'export_by_string', 'string_filter', 'pageableDTO'])
        } else {
          filtered.export_by_string = false
          filtered.string_filter = null
          filtered.process_ids = [this.processCSVExport.id]
        }

        this.fetchCantToExport([this.$toast, filtered])
          .finally(() => {
            this.number = parseInt(this.cantToExport)
            this.loadings.process = false
          })
      },
      async exportCSV(process) {
        this.loadings.export = true

        const keys = [
          'storage_device_types_ids',
          'quebra_range',
          'sellers_names',
          'contracts_names',
          'contracts_dates',
          'volumeB_range',
          'volumeN_range',
          'surveyStateType',
          'template_names',
          'min_punctuation',
          'max_punctuation',
          'storage_devices_ids',
          'document_authority_ids',
          'document_type_ids',
          'document_description_ids',
          'document_date',
          'expiration_date',
        ]

        if (!this.filters.pageableDTO)
          this.filters.pageableDTO = this.pageable

        let filters = _.omit(this.filters, keys)

        filters.record_ids = []

        if (this.selectedItems && this.selectedItems.length > 0) {
          filters.record_ids =
            this.selectedItems && this.selectedItems.length > 0
              ? this.getByProperty(this.selectedItems, 'id')
              : []
        }
        filters.language = LanguageService.getLenguajeName()
        filters.process_ids = [process.id]

        if ((this.search && this.search !== '') && !(this.selectedItems && this.selectedItems.length > 0)) {
          filters.export_by_string = true
          filters.string_filter = {
            companyId: this.profile.company_id,
            stringFilter: this.search,
            storageDeviceTypeDTO: {
              id: this.filterRecord.stageId,
            },
            process_id: process.id,
            pageableDTO: this.filters.pageableDTO
          }

          filters = _.pick(filters, ['language', 'process_ids', 'export_by_string', 'string_filter', 'pageableDTO'])
        } else {
          filters.export_by_string = false
          filters.string_filter = null
        }

        let isAsync = false;
        if (this.selectedItems && this.selectedItems.length > 0) {
          isAsync = this.selectedItems.length > this.config.company.export.recordExportLimit
        } else {
          isAsync = this.totalFromServer > this.config.company.export.recordExportLimit
        }

        await this.exportRecordsAction([
          filters,
          null,
          'excel',
          this.$toast,
          isAsync
        ]).finally(() => {
          this.loadings.export = false
          this.dialogExportCSVClose()
        })
      },

      async dialogExportCSVClose() {
        this.processCSVExport = null
        this.loadings.process = false
        this.dialog = false
        this.number = null

        if (!(this.selectedItems && this.selectedItems.length > 0))
          await this.seeProcess('all')
      },

      exportDialogCSV() {
        if (
          this.filtersObjects.process_ids &&
          this.filtersObjects.process_ids.length === 1
        ) {
          let process = null
          if (typeof this.filtersObjects.process_ids[0] === 'number') {
            process = this.allProcess.find(process => process.id === this.filtersObjects.process_ids[0])
          } else {
            process = this.filtersObjects.process_ids[0]
          }
          this.processCSVExport = _.cloneDeep(process)
          this.filterByProcessCSV()
        } else if (
          this.filtersObjects.process_ids &&
          this.filtersObjects.process_ids.length > 1
        ) {
          this.allProcess = this.filtersObjects.process_ids.map(id => {
            if (typeof id === 'number') {
              return this.allProcess.find(process => process.id === id)
            } else {
              return id
            }
          })
        } else if (this.selectedItems && this.selectedItems.length === 1) {
          let process = _.filter(this.allProcess, {
            id: this.selectedItems[0].process.id,
          })
          this.processCSVExport = _.cloneDeep(process[0])
          this.filterByProcessCSV()
        } else if (this.selectedItems && this.selectedItems.length > 1) {
          let process = []
          let ids = []
          _.forEach(this.selectedItems, s => {
            process.push(_.filter(this.allProcess, { id: s.process.id })[0])
            if (!ids.includes(s.process.id)) {
              ids.push(s.process.id)
            }
          })
          if (ids.length === 1) {
            this.processCSVExport = process[0]
            this.filterByProcessCSV()
          } else {
            this.allProcess = process
          }
        } else {
          if (!(this.allProcess.length > 0)) {
            this.loadings.process = true
            this.fetchAllProcess([this.profile, this.$toast]).finally(() => {
              this.loadings.process = false
            })
          }
        }
        this.$emit('closeMenu', false)
        this.dialog = true
      },
      async seeProcess(action) {
        this.loadings.process = true
        if (action === 'all') {
          await this.fetchAllProcess([this.profile, this.$toast]).finally(
            () => {
              this.loadings.process = false
            }
          )
        } else if ('selected') {
          this.allProcess = this.filtersObjects.process_ids.map(id => {
            if (typeof id === 'number') {
              return this.allProcess.find(process => process.id === id)
            } else {
              return id
            }
          })
          this.loadings.process = false
        }
      },
      getByProperty(array, property) {
        return _.map(array, property)
      },
      translate(key, uppercase) {
        return LanguageService.getKey3(key)
      }
    },
    destroyed() {
      this.dialog = false
    }
  }
</script>

<style scoped>

</style>